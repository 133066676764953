<template>
	<div class="main-content">
		<!--BreadCrumbs-->
		<ktv-breadcrumb
			:folder="$t('Premium Points', 'Premium Points')"
			page=""
			:title="title"
		/>
		<!--Tables Grid-->
		<TablesGridDetail />
	</div>
</template>
<script>
	import { KtvBreadcrumb } from "@/components"
	import TablesGridDetail from "./tables/TablesGridDetail"

	export default {
		metaInfo: {
			title: "Agri Input Request",
		},
		components: {
			KtvBreadcrumb, TablesGridDetail
		},
		data() {
			return {
				title : ''
			}
		},
		mounted(){
			this.title =  "Agri Request Detail " + this.$route.params.premiumDisburseId
		}
	}
</script>
