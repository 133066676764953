<template>
	<div>
		<b-row class="p-1">
			<b-col>
				<b-row>
					<b-col cols="4" class="font-weight-bold text-20">{{ farmerName }}</b-col>
				</b-row>
				<b-row>
					<b-col cols="4" class="font-weight-bold">ID: {{ farmerid }}</b-col>
				</b-row>
			</b-col>
		</b-row>
		<b-row class="mb-1 p-1" style="background: #f9f7f7">
			<b-col>
				<b-row>
					<b-col cols="4">{{ $t('Status', 'Status') }}</b-col>
					<b-col offset-md="2">{{ $t('Pickup Point', 'Pickup Point') }}</b-col>
					<b-col>{{ $t('Address', 'Address') }}</b-col>
				</b-row>
				<b-row>
					<b-col cols="4" class="font-weight-bold">{{ status }}</b-col>
					<b-col offset-md="2" class="font-weight-bold">{{ pickupPoint }}</b-col>
					<b-col class="font-weight-bold">{{ address }}</b-col>
				</b-row>
			</b-col>
		</b-row>
		<ktv-table
			mode="remote"
			:fixed-header="true"
			:is-loading="loading"
			:columns="columns"
			:rows="rows"
			:total-rows="totalRecords"
			:rows-per-page="serverParams.limit"
			:search-custom-enabled="false"
			:search-enabled="false"
			:filter="false"
			@on-open-filter="openFilter()"
			@on-page-change="onPageChange"
			@on-sort-change="onSortChange"
			@on-per-page-change="onPerPageChange"
		>			 
			<template #columns="{ props }">  
				{{ $t(props.column.label, props.column.label) }}
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field === 'name'" class="ml-2">{{ props.row.name }} </span>
				<span v-if="props.column.field === 'totalPrice'" style="float: right" class="mr-2">
					{{ props.row.totalPrice | IDR }}
				</span>
				<span v-if="props.column.field === 'quantity'" style="float: right" class="mr-2">
					{{ props.row.quantity }}
				</span>
				<span v-if="props.column.field === 'unit'" style="float: right" class="mr-2">
					{{ props.row.unit }}
				</span>
				<span v-else-if="props.column.field === 'price'" style="float: right" class="mr-2">
					{{ props.row.price | IDR }}
				</span>
			</template>
		</ktv-table>
	</div>
</template>
<script>
	const column = [
		{
			label: "Name",
			field: "name",
			thClass: "text-left",
			sortable: false,
			width: '250px'
		},
		{
			label: "Unit",
			field: "unit",
			thClass: "text-center",
			sortable: false,
			width: '150px'
		},
		{
			label: "Quantity",
			field: "quantity",
			thClass: "text-right",
			sortable: false,
			width: '150px'
		},
		{
			label: "Price(/unit)",
			field: "price",
			thClass: "text-right",
			sortable: false,
			width: '150px'
		},
		{
			label: "Total Price",
			field: "totalPrice",
			thClass: "text-right",
			sortable: false,
			width: '150px'
		},
	]

	import { KtvTable } from "@/components"
	import { mapActions, mapGetters } from "vuex"

	export default {
		components: {
			KtvTable,
		},
		props: {
			ptPremiumDisburseId: {
				type: Number,
				default: null,
			},
			farmerUid: {
				type: String,
				default: null,
			},
		},
		data() {
			return {
				loading: false,
				/* KtvTable property */
				columns: column,
				rows: [],
				totalRecords: 0,
				serverParams: {},
				sortKey: "",
				/* End KtvTable property */
				farmerName: "",
				farmerid: "",
				status: "",
				pickupPoint: "",
				address: "",
			}
		},
		computed: {
			...mapGetters({}),
		},
		mounted() {
			this.loadItems() 
		},
		created() {},
		methods: {
			...mapActions({
				ActionTablesGridProgressDetail: "AGRIINPUTREQUEST/ActionTablesGridProgressDetail",
			}),
			/**
			 * KtvTables Methods
			 */
			updateParams() {},
			// Untuk Paging Next - Previous hit
			onPageChange() {},
			// Untuk melakukan Jumlah dropdown Rowsperpages
			onPerPageChange() {},
			// Untuk melakukan Sorting data
			onSortChange() {},
			// Untuk Load Data
			loadItems() {
				this.loading = true
				this.serverParams.farmerUid = this.farmerUid
				this.serverParams.disburseId = this.ptPremiumDisburseId
				this.ActionTablesGridProgressDetail(this.serverParams).then((resp) => {
					this.loading = false
					if (resp) {
						this.rows = resp.data
						this.totalRecords = resp.data.length
						this.farmerName = resp.farmerName
						this.farmerid = resp.farmerid
						this.status = resp.status
						this.pickupPoint = resp.pickupPoint
						this.address = resp.address
					}
				})
			},
			formatPrice(value) {
				let val = (value / 1).toFixed().replace(".", ",")
				return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
			},
		},
	}
</script>
 
