<template>
	<div>
		<b-row class="mb-4">
			<b-col>
				<a v-b-tooltip.hover href="/premium-point/agri-input/request">
					<i class="i-Arrow-Left-2 text-10" />
					{{ $t("Back to List", "Back to List") }}
				</a>
			</b-col>
		</b-row>
		<b-row class="m-1 mb-4 p-3" style="background: #f3f4f6">
			<b-col md="6">
				<b-row>
					<b-col cols="2">{{ $t("Farmer", "Farmer") }}</b-col>
					<b-col cols="4">{{ $t("Agri Input Requested", "Agri Input Requested") }}</b-col>
					<b-col cols="4">{{ $t("Premium Points", "Premium Points") }}</b-col>
				</b-row>
				<b-row>
					<b-col cols="2" class="font-weight-bold">{{ formatPrice(totalfarmer) }}</b-col>
					<b-col cols="4" class="font-weight-bold">{{ formatPrice(totalrequest) }}</b-col>
					<b-col cols="4" class="font-weight-bold">{{ formatPrice(Math.round(totalPremium)) }}</b-col>
				</b-row>
			</b-col>
			<b-col offset-sm="2">
				<b-row class="text-right">
					<b-col cols="12">{{ $t("CVC Association", "CVC Association") }}</b-col>
					<b-col class="font-weight-bold">{{ cvcAssoc }} </b-col>
				</b-row>
			</b-col>
			<b-col>
				<b-row class="text-right">
					<b-col cols="12">{{ $t("Created At", "Created At") }}</b-col>
					<b-col class="font-weight-bold">{{ createdAt ? getDate(createdAt) : "-" }}</b-col>
				</b-row>
			</b-col>
		</b-row>

		<ktv-table
			mode="remote"
			:search-placeholder="$t('Searching by Farmer Name', 'Searching by Farmer Name')"
			:fixed-header="true"
			:is-loading="loading"
			:columns="columns"
			:rows="rows"
			:total-rows="totalRecords"
			:rows-per-page="serverParams.limit"
			:search-enabled="false"
			:filter="false"
			@on-open-filter="openFilter()"
			@on-page-change="onPageChange"
			@on-sort-change="onSortChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearchFilter"
		>
			<template #columns="{ props }">
				{{ $t(props.column.label, props.column.label) }}
			</template>
			<template #actionleft>
				<b-row>
					<b-col cols="col-3 ml-2">
						<b-button variant="success" class="mr-2" @click="exportReport">
							<i class="i-Download text-18 text-white mr-2" />
							{{ $t("Report Agri Input Request", "Report Agri Input Request") }}
						</b-button>
					</b-col>
					<b-col>
						<b-button variant="primary" class="mr-2" :disabled="isDisable" @click="sentToCvc">
							<i class="i-Letter-Sent text-18 text-white mr-2" /> {{ $t("Send order to cvc", "Send order to cvc") }}
						</b-button>
					</b-col>
				</b-row>
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field === 'button'">
					<b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
						<template #button-content>
							<span><i class="nav-icon i-Arrow-Down-in-Circle" /></span>
						</template>
						<b-dropdown-item href="#" @click="onPopup(props.row.ptPremiumDisburseId, props.row.farmerUid)">
							<a v-b-tooltip.hover href="#" title="Edit">
								<i class="i-Pen-3 text-18 text-success mr-2" />
								{{ $t("Detail", "Detail") }}
							</a>
						</b-dropdown-item>
					</b-dropdown>
				</span>

				<span v-else-if="props.column.field === 'premiumPoint'" style="float: right" class="mr-2">
					{{ formatPrice(Math.round(props.row.premiumPoint)) }}
				</span>
				<span v-else-if="props.column.field === 'agriInputQuantity'">
					<center>{{ formatPrice(props.row.agriInputQuantity) }}</center>
				</span>
			</template>
		</ktv-table>

		<ktv-modal v-model="modalsFilter" size="lg" :title="$t('Agri Input Request Detail', 'Agri Input Request Detail')" @close="modalsFilter = false">
			<template #content>
				<tables-grid-request-detail :pt-premium-disburse-id="ptPremiumDisburseId" :farmer-uid="farmerUid" />
			</template>
		</ktv-modal>
	</div>
</template>

<script>
	const column = [
		{
			label: "Action",
			field: "button",
			html: true,
			tdClass: "text-center",
			thClass: "text-center",
			sortable: false,
		},
		{
			label: "ID",
			field: "ptPremiumDisburseDetailId",
			thClass: "text-left",
			width: "150px",
		},
		{
			label: "Name",
			field: "farmerName",
			thClass: "text-left",
			width: "250px",
		},
		{
			label: "Premium Points",
			field: "premiumPoint",
			thClass: "text-left",
			width: "200px",
		},
		{
			label: "Agri Input",
			field: "agriInputQuantity",
			thClass: "text-center",
			width: "150px",
		},
		{
			label: "Pickup Point",
			field: "pickupPointCvcId",
			thClass: "text-center",
			width: "150px",
		},
		{
			label: "Status",
			field: "statusDescription",
			thClass: "text-left",
			width: "250px",
		},
	]

	import { KtvTable, KtvModal } from "@/components"
	import { mapActions } from "vuex"
	import TablesGridRequestDetail from "../tables/TablesGridRequestDetail"

	export default {
		components: {
			KtvModal,
			KtvTable,
			TablesGridRequestDetail,
		},
		data() {
			return {
				loading: false,
				modalsFilter: false,
				/* KtvTable property */
				columns: column,
				rows: [],
				totalRecords: 0,
				serverParams: {
					page: 1,
					limit: 10,
				},
				sortKey: "",
				filter: {
					farmerName: null,
				},
				/* End KtvTable property */
				createdAt: "",
				totalfarmer: "",
				totalrequest: "",
				totalPremium: "",
				ptPremiumDisburseId: "",
				farmerUid: "",
				cvcAssoc: "",
				isDisable: false,
			}
		},
		mounted() {
			this.loadItems()
		},
		methods: {
			...mapActions({
				ActionTablesGridProgress: "AGRIINPUTREQUEST/ActionTablesGridProgress",
				ActionSentToCvc: "AGRIINPUTREQUEST/ActionSentToCvc",
				getReport: "PREMIUMPOINT/getReport",
			}),
			onPopup(ptPremiumDisburseId, farmerUid) {
				this.ptPremiumDisburseId = ptPremiumDisburseId
				this.farmerUid = farmerUid
				this.modalsFilter = true
			},
			getDate(timestamp) {
				const date = new Date(timestamp)

				return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`
			},
			/**
			 * KtvTables Methods
			 */
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			// Untuk Paging Next - Previous hit
			onPageChange(params) {
				this.updateParams({ page: params.currentPage })
				this.loadItems()
			},
			// Untuk melakukan Jumlah dropdown Rowsperpages
			onPerPageChange(params) {
				this.updateParams({ limit: params.currentPerPage })
				this.loadItems()
			},
			// Untuk melakukan Sorting data
			onSortChange(params) {
				this.sortKey = params[0].field
				this.columns[params[0].field] = !this.columns[params[0].field]
				let oParams = {
					namaField: params[0].field,
					orderBy: this.columns[params[0].field] === false ? "asc" : "desc",
				}
				const NewParams = new URLSearchParams(oParams).toString()
				this.serverParams.order = NewParams
				this.loadItems()
			},
			loadItems() {
				this.loading = true
				let id = this.$route.params.premiumDisburseId.replace("PRE", "")
				this.serverParams.disburseId = id
				this.ActionTablesGridProgress(this.serverParams).then((resp) => {
					this.loading = false

					if (resp) {
						this.rows = resp.data
						this.totalRecords = resp.totalRow
						this.createdAt = resp.createdAt
						this.totalPremium = resp.totalPremium
						this.cvcAssoc = resp.cvcAssoc
						this.totalfarmer = this.totalRecords
						this.totalrequest = resp.totalAgriInput
						if (resp.statusDisburseType !== 224) {
							this.isDisable = true
						}
					}
				})
			},
			formatPrice(value) {
				let val = (value / 1).toFixed().replace(".", ",")
				return !isNaN(val) ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
			},
			sentToCvc() {
				this.$swal({
					type: "warning",
					title: this.$t("Confirmation", "Confirmation"),
					text: this.$t("Are you sure you want to sent the data?", "Are you sure you want to sent the data?"),
					showConfirmButton: true,
					showCancelButton: true,
					confirmButtonText: this.$t("Submit", "Submit"),
					cancelButtonText: this.$t("Cancel", "Cancel"),
				}).then((isConfirm) => {
					if (isConfirm.value === true) {
						this.$swal.fire({
							title: `${this.$t("Loading", "Loading")}...`,
							html: this.$t("Data will be saved", "Data will be saved"),
							allowOutsideClick: false,
							onBeforeOpen: () => {
								this.$swal.showLoading()
							},
						})
						let id = this.$route.params.premiumDisburseId.replace("PRE", "")
						const payload = {
							premiumDisburseId: id,
						}
						this.ActionSentToCvc(payload)
							.then(() => {
								this.isDisable = true
							})
							.catch(() => {
								this.isDisable = false
							})
					}
				})
			},
			onSearchFilter(params) {
				const request = {
					farmerName: params === null || params === "" ? "" : params,
				}
				Object.keys(request).forEach((k) => !request[k] && request[k] !== undefined && delete request[k])
				const NewParams = new URLSearchParams(request).toString()
				this.serverParams.search = decodeURIComponent(NewParams.replace(/\+/g, "%20"))
				this.loadItems()
			},
			downloadFile(url, fileName) {
				fetch(url)
					.then((response) => response.blob())
					.then((blob) => {
						const link = document.createElement("a")
						link.href = URL.createObjectURL(blob)
						link.download = fileName
						link.click()
					})
					.catch((error) => {
						const err = error.toJSON()
						const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

						this.$swal(err.name, errorMessage, "error")
					})
			},
			exportReport() {
				this.loading = true

				this.getReport({ disburseId: parseInt(String(this.$route.params.premiumDisburseId).replaceAll("PRE", ""), 10), type: "agriInput" })
					.then((transactionReport) => {
						transactionReport.forEach(({ fileName, url }) => {
							const fileExtension = fileName.split(".").pop()

							const replacedFileName = `${this.$t("Report Agri Input Request", "Report Agri Input Request")} (${
								this.$route.params.premiumDisburseId
							}).${fileExtension}`
							this.downloadFile(url, replacedFileName)
						})

						this.loading = false
					})
					.catch(() => {
						this.loading = false
					})
			},
		},
	}
</script>

<style>
	.modal-dialog .vgt-global-search.vgt-clearfix {
		padding: 0 14px;
		border-radius: 4px;
		height: 0px;
	}
</style>
